import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import HomeBackground from "@brand/Home/desktop/HomeBackground";
import HomeMarketing from "@brand/Home/desktop/HomeMarketing";
import "./HomeDesktop.scss";
import CookiePolicyFooterContainer from "app/pages/.shared/CookiePolicyFooter/CookiePolicyFooterContainer";
import SignupContainer from "app/pages/Auth/Signup/SignupContainer";
import LoginContainer from "app/pages/Auth/Signin/LoginContainer";
import ResetPasswordContainer from "app/pages/Auth/ResetPassword/ResetPasswordContainer";
import ChangePasswordContainer from "app/pages/Auth/ChangePassword/ChangePasswordContainer";
import { Navigate, Route, Routes } from "react-router-dom";
import IconAtolProtected from "app/pages/.shared/static/icons/IconAtolProtected";
import IconABTACertification from "app/pages/.shared/BookWithConfidence/IconABTACertification";
import ProductListBlockContainer from "app/pages/.shared/DynamicBlocks/blocks/ProductListBlock/ProductListBlockContainer";
import AuthFooter from "@brand/Auth/AuthFooter";

const HomeDesktop = ({ shop, home = {} }) => {
	return (
		<div className="home-desktop" data-testid="home-desktop">
			<section className="home-desktop__hero">
				<div className="home-desktop__photo">
					<HomeBackground
						image={home.images?.imageDesktop}
						thumbnail={home.images?.imageDesktopThumbnail}
					/>
				</div>

				<div className="home-desktop__container">
					<div className="home-desktop__wrapper">
						<div className="home-desktop__marketing">
							<HomeMarketing image={home.images?.textDesktop} />
						</div>

						<div className="home-desktop__main">
							<div className="home-desktop__login">
								<section className="home-desktop__form">
									<Routes>
										<Route
											path="/login"
											element={
												<LoginContainer
													signinButtonLabel={
														<FormattedMessage id="home.header.button.signin.now" />
													}
													headline={false}
													showTopFacebook={true}
													footer={<AuthFooter />}
												/>
											}
										/>

										<Route
											path="/signup/*"
											element={
												<SignupContainer
													headline={false}
													showTopFacebook={true}
													footer={<AuthFooter />}
												/>
											}
										/>

										<Route
											path="/changepassword"
											element={<ChangePasswordContainer />}
										/>

										<Route
											path="/resetpassword"
											element={<ResetPasswordContainer />}
										/>

										<Route path="/*" element={<Navigate to="./login" />} />
									</Routes>
								</section>
							</div>
						</div>
					</div>
				</div>

				{shop === "en-GB" && (
					<div className="home-desktop__certifications" data-testid="certifications">
						<IconAtolProtected height={40} width={40} />
						<IconABTACertification height={40} width={70} noColor={true} />
					</div>
				)}
			</section>

			<section className="home-desktop__products">
				<ProductListBlockContainer shortVersion />
			</section>

			<CookiePolicyFooterContainer />
		</div>
	);
};

HomeDesktop.propTypes = {
	shop: PropTypes.string,
	home: PropTypes.object,
};

export default HomeDesktop;
