import { memo } from "react";
import { resolutionPropType } from "app/utils/propTypes";
import "./HomeMobile.scss";
import SignupContainer from "app/pages/Auth/Signup/SignupContainer";
import CookiePolicyFooterContainer from "app/pages/.shared/CookiePolicyFooter/CookiePolicyFooterContainer";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import LoginContainer from "app/pages/Auth/Signin/LoginContainer";
import ResetPasswordContainer from "app/pages/Auth/ResetPassword/ResetPasswordContainer";
import ChangePasswordContainer from "app/pages/Auth/ChangePassword/ChangePasswordContainer";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomeBackgroundMobile from "@brand/Home/mobile/HomeBackgroundMobile";
import HomeMarketingMobile from "@brand/Home/mobile/HomeMarketingMobile";
import ProductListBlockContainer from "app/pages/.shared/DynamicBlocks/blocks/ProductListBlock/ProductListBlockContainer";

const HomeMobile = ({ resolution, home = {} }) => {
	const location = useLocation();

	return (
		<section className="home-mobile" data-testid="home-mobile">
			<section className="home-mobile__hero">
				<div className="home-mobile__photo">
					<HomeBackgroundMobile
						resolution={resolution}
						image={home.images?.imageMobile}
						thumbnail={home.images?.imageMobileThumbnail}
					/>
				</div>
				<div className="home-mobile__main">
					<div className="home-mobile__message">
						<HomeMarketingMobile image={home.images?.textMobile} />
					</div>

					<section className="home-mobile__form">
						<Routes>
							<Route
								path="/login"
								element={
									<LoginContainer
										signinButtonLabel={
											<FormattedMessage id="home.header.button.signin.now" />
										}
										headline={false}
										showTopFacebook={true}
									/>
								}
							/>
							<Route
								path="/signup"
								element={
									<SignupContainer headline={false} showTopFacebook={true} />
								}
							/>
							<Route
								path="/changepassword"
								element={<ChangePasswordContainer location={location} />}
							/>
							<Route path="/resetpassword" element={<ResetPasswordContainer />} />
							<Route path="/*" element={<Navigate to="./signup" />} />
						</Routes>
					</section>
				</div>
			</section>
			<section className="home-mobile__products">
				<ProductListBlockContainer shortVersion />
			</section>
			<CookiePolicyFooterContainer />
		</section>
	);
};

HomeMobile.propTypes = {
	resolution: resolutionPropType,
	home: PropTypes.object,
};

export default memo(HomeMobile);
