import { memo } from "react";
import PropTypes from "prop-types";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";

const IMG_SIZES = {
	large: { width: 1847 },
	xlarge: { width: 1847 },
};

const HomeBackground = ({ image, thumbnail }) => {
	return (
		<BackgroundProgressiveImg
			sizes={IMG_SIZES}
			src={image}
			thumbnail={thumbnail}
			backgroundPosition="right"
		/>
	);
};

HomeBackground.propTypes = {
	image: PropTypes.string,
	thumbnail: PropTypes.string,
};

export default memo(HomeBackground);
